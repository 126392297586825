.container {
  border-image-source: url("../assets/container-border.png");
  border-image-slice: 30;
  border-image-width: 20px;
  border-image-outset: 0;
  border-image-repeat: repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.container-content {
  background-color: #c7b88f;
  width: 100%;
  height: 100%;
  min-width: 250px;
  z-index: -1;
  display: flex;
  align-items: center;
  text-align: center;
}
