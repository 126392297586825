* {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.header-parallax {
  overflow: hidden;
  display: inline-block;
  flex-direction: column;
  width: 100%;
}

.parallax {
  height: 40em;
  overflow: hidden;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 1399px) {
  .cloud-animation,
  .cloud-animation2,
  .ground-container,
  .train {
    display: none !important;
  }
}

.header-buttons-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 3em;
}

.header-buttons-mobile h3 {
  padding: 1em;
  text-shadow: 2px 2px 2px #000;
  font-size: x-large;
}

.header-mobile {
  display: none;
  background-image: url("../assets/header-mobile.png");
  width: 100%;
  height: 640px;
}

.parallax-layer {
  background-position: bottom center;
  background-size: auto;
  background-repeat: repeat-x;
  width: 100%;
  height: 55em;
  position: fixed;
  z-index: -1;
}

.logo {
  margin-top: 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  height: 35em;
}

.solofarming-logo {
  width: 700px;
}

.logo h3 {
  /* add shadow black */
  text-shadow: 2px 2px 2px #000;
  font-size: xx-large;
}

.header-buttons {
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.layer3 {
  background-image: url("../assets/mountain1.png");
  background-size: 60em 40em;
  height: 65em;
}

.layer2 {
  height: 45em;
  background-size: 100em 30em;
  background-image: url("../assets/mountain2.png");
}

.layer1 {
  background-image: url("../assets/sky.png");
  background-size: cover;
}

.ground-container,
.ground-mobile {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ground {
  background-repeat: no-repeat;
  z-index: 1;
  background-position: bottom center;
  height: 624px;
  margin: 0 auto;
}

.ground-right .ground-left {
  flex-grow: 1;
  background-repeat: repeat-x;
  background-size: contain;
}

.ground-left {
  background-image: url("../assets/ground-left.png");
  width: 100%;
  height: 624px;
}

.ground-right {
  background-image: url("../assets/ground-right.png");
  width: 100%;
  height: 624px;
}

.gifs-container {
  text-align: center;
}

.npc-farming {
  position: absolute;
  margin-top: 350px;
  margin-left: -500px;
  z-index: 5;
}

.npc-mining {
  position: absolute;
  margin-top: 470px;
  margin-left: 250px;
  z-index: 5;
}

.npc-livestock {
  position: absolute;
  margin-top: 470px;
  margin-left: -200px;
  z-index: 5;
}

.npc-fishing {
  position: absolute;
  margin-top: 370px;
  margin-left: 600px;
  z-index: 5;
}

.house {
  position: absolute;
  top: 40px;
  z-index: 5;
}

.content h1 {
  font-size: 3rem;
}

.content p {
  font-size: 1.5rem;
}

.cloud-animation img {
  position: absolute;
  top: 10%;
  left: -100%;
  animation: moveCloud 200s linear infinite;
}

.cloud-animation2 img {
  z-index: 2;
  top: 30%;
  position: absolute;
  left: -100%;
  animation: moveCloud2 115s linear infinite;
}

.train img {
  z-index: 2;
  top: 30px;
  position: absolute;
  left: -100%;
  animation: train 15s linear infinite;
}

@keyframes moveCloud {
  0% {
    left: 30%;
  }
  100% {
    left: 100%;
  }
}

@keyframes moveCloud2 {
  0% {
    left: 60%;
  }
  100% {
    left: 100%;
  }
}

@keyframes train {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
