.carousel-container {
  position: relative;
  width: 100%;
  margin: auto;
  background-image: url("../assets/wall.png");
  background-repeat: repeat;
}

.container-content {
  z-index: 2 !important;
}

.border-top-wall {
  position: absolute;
  top: -48px;
  width: 100%;
  height: 48px;
  background-image: url("../assets/top-wall.png");
  background-repeat: repeat-x;
}

.carousel-slide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.slide {
  display: none;
}

.active {
  display: block;
}

.dot-container {
  text-align: center;
  margin-top: 20px;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.active-dot,
.dot:hover {
  background-color: #717171;
}

.gradient {
  width: 100%;
  height: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(61, 61, 82, 0),
      #3d3d52 68%
    ),
    linear-gradient(to bottom, transparent, transparent);
}

.image {
  width: 400px;
  height: auto;
}

@media screen and (max-width: 400px) {
  .image {
    width: 300px;
    height: auto;
  }
}

@media screen and (min-width: 501px) and (max-width: 900px) {
  .image {
    width: 600px;
    height: auto;
  }
}

@media screen and (min-width: 901px) {
  .image {
    width: 900px;
    height: auto;
  }
}

@media screen and (min-width: 1200px) {
  .image {
    width: 1100px;
    height: auto;
  }
}
