@font-face {
  font-family: "DePixel";
  src: url("./assets/fonts/DePixelHalbfett.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SupplyCenter";
  src: url("./assets/fonts/SupplyCenter-0W9nz.ttf");
  font-weight: normal;
  font-style: normal;
}
h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

p {
  font-size: 14px;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
