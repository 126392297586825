.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  padding-top: 8em;
  padding-bottom: 9em;
}

.container-skills {
  padding-top: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
  width: 100%;
}

.skills .container {
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  align-items: center;
  text-align: center;
  width: 340px;
}

.item-description {
  padding: 10px;
}

.item {
  display: flex;
  align-items: center;
  text-align: center;
  color: #6a5039;
}

.item img {
  max-width: 100%;
  height: auto;
  display: block;
}

.item-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
  width: 100%;
}

h1,
h3 {
  color: #ffffff;
}

.skills h1 {
  margin-bottom: 24px;
}

.skills h3 {
  margin-bottom: 80px;
}

.item-content h2 {
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .item {
    width: calc(100% - 20px); /* 1 item per row with gap */
  }
}
