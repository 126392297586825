.economy {
  padding-top: 10em;
  display: flex;
  background-color: #3d3d52;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #ffffff;
}

.economy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3em;
}

.tokens-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 4em;
}

.token-info {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 1em;
}

.economy-diagram {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4em;
  width: 100%;
}

.economy-diagram img {
  width: 70%;
}
