.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b08f4d;
  z-index: 1;
}

h1 {
  font-family: "SupplyCenter";
}

@media screen and (max-width: 500px) {
  .solofarming-logo {
    width: 370px !important;
  }

  .logo h3 {
    font-size: medium !important;
  }

  .skills {
    padding: 50px 0px 100px 0px !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 799px) {
  .solofarming-logo {
    width: 470px !important;
  }

  .logo h3 {
    font-size: large !important;
  }
}

@media screen and (min-width: 800px) and (max-width: 1399px) {
  .solofarming-logo {
    width: 770px !important;
  }

  .logo h3 {
    font-size: x-large !important;
  }
}

@media screen and (min-width: 1399px) {
  .solofarming-logo {
    width: 900px !important;
  }

  .ground-mobile {
    display: none !important;
  }

  .logo h3 {
    font-size: xx-large !important;
  }
}

@media screen and (min-width: 370px) and (max-width: 699px) {
  .section-content {
    width: 340px;
  }

  .social-links-container {
    flex-direction: column !important;
    gap: 3em !important;
    height: 100% !important;
  }

  .tokens-container {
    flex-direction: column !important;
    gap: 3em !important;
    text-align: center !important;
    justify-content: center !important;
  }

  .token-info {
    align-items: center !important;
  }

  .footer-container {
    flex-direction: column !important;
    align-items: center !important;
    gap: 3em !important;
  }
}

@media screen and (min-width: 700px) {
  .section-content {
    width: 650px;
  }
}

@media screen and (min-width: 1400px) {
  .section-content {
    width: 1200px;
  }

  .solofarming-logo {
    width: 800px !important;
  }
}

h2,
h3,
p {
  font-family: "DePixel";
}

a {
  color: #ffffff;
  text-decoration: underline;
}

.button {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: "DePixel";
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-primary {
  background-color: #75513f;
  color: #fff;
}

.button-light {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.85);
}

.button-primary:hover,
.button-light:hover,
.button-primary:focus,
.button-light:focus {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-primary:hover,
.button-primary:focus {
  background-color: #967248;
}

.button-light:hover,
.button-light:focus {
  border-color: rgba(0, 0, 0, 0.15);
}

.button-primary:hover,
.button-light:hover {
  transform: translateY(-1px);
}

.button-primary:active,
.button-light:active {
  transform: translateY(0);
}

.button-light:active {
  background-color: #f0f0f1;
}
