.roadmap {
  width: 100%;
  background-color: #3d3d52;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.roadmap img {
  width: 100%;
}

.roadmap-container {
  padding-top: 10em;
  padding-bottom: 10em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3em;
}
