.social-links {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #718f3f;
  width: 100%;
}

.social-links-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 3em;
  padding-bottom: 3em;
  text-align: left;
  height: 20em;
}

.social-links-container .content-info {
  max-width: 50%;
}

.top-gram {
  position: relative;
  top: -78px;
  width: 100%;
  height: 50px;
  background-image: url("../assets/top-gram.png");
  background-repeat: repeat-x;
  z-index: 2 !important;
}

.social-links p {
  color: #ffffff;
  font-size: 1rem;
}

.social-links button {
  width: 120px;
}

.social-links .icon {
  width: 50px;
  fill: #ffffff;
}

.links-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  max-width: 50%;
  text-wrap: wrap;
  gap: 2em;
}

.links-container {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: start;
}
