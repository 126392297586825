.footer {
  height: 12em;
  display: flex;
  background-color: #3d3d52;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.policy {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1em;
}
